import { createSlice } from '@reduxjs/toolkit';

import produce from 'immer';

const initialState = {
  wkk: {},
  goz: {},
};

const machineSlice = createSlice({
  name: 'machine',
  initialState,
  reducers: {
    setWkkFasts(state, action) {
      const data = action.payload;

      return produce(state, draft => {
        draft.wkk = {
          ...draft.wkk,
          power: data[11],
          power2: data[12],
          throttleValve: data[18],
          throttleValve2: data[19],
          rpm: data[20],
          rpm2: data[21],
          lambda: data[38],
          lambda2: data[39],
          engineStatus: data[22],
          engineStatus2: data[23],
          engineError: data[24],
          engineError2: data[25],
          producedEnergy: data[15],
          producedEnergy2: data[16],
          gasUsage: data[48],
          gasUsage2: data[49],
          waterTempMotorOut: data[59],
          waterTempMotorOut2: data[60],
          throttlePosition: data[40],
          throttlePosition2: data[41],
          exitPumpRunning: data[28],
          feedingStatus: data[32],
          feedingError: data[46],
          feedingCycleLevel: data[33],
          exitPumpTime: data[34],
          exitPumpAmount: data[35],
          intakePumpTime: data[36],
          intakePumpAmount: data[37],
          statusValvePumpIn: data[26],
          statusValvePumpOut: data[27],
          statusValveRetourReactor: data[47],
          valveCurrentActuator: data[7],
          gasBufferLevel: data[5],
          voltagePerPhase1: data[8],
          voltagePerPhase2: data[9],
          voltagePerPhase3: data[10],
          frequencyPerPhase: data[44],
          angleBetweenPhase1And2: data[45],
          powerConsumption: data[13],
          consumedEnergy: data[17],
          reactorTemp: data[1],
          waterTempIn: data[2],
          waterTempOut: data[3],
          coolingWaterValvePosition: data[54],
          coolingWaterValvePosition2: data[55],
          hydrostaticPressure: data[4],
          mixer: data[30],
          batteryVoltage: data[14],
          controlCardTemperature: data[6],
          waterPressure: data[52],
          statusCoolingValve: data[31],
          torchStatus: data[61],
          servoAutoManual: data[62],
          servoAutoManual2: data[63],
          engineTemp: data[42],
          engineTemp2: data[43],
          valve2: data[26],
          valve3: data[27],
          valve4: data[47],
        };
      });
    },
    setWkkSlows(state, action) {
      const data = action.payload;
      return produce(state, draft => {
        draft.wkk = {
          ...draft.wkk,
          maxPower: data[78],
          maxPower2: data[79],
          desiredPower: data[23],
          desiredPower2: data[24],
          operatingHours: data[6],
          operatingHours2: data[7],
          totalOperatingHours: data[48],
          totalOperatingHours2: data[49],
          hoursUntilService: data[31],
          hoursUntilService2: data[32],
          engineType: data[80],
          engineType2: data[81],
          idBio: data[86],
          idBio2: data[87],
          idH2s: data[88],
          idH2s2: data[89],
          bioId: data[90],
          bioId2: data[91],
          h2sLevelReactor: data[4],
          h2sLevelReactor2: data[51],
          h2sLevelMotor: data[37],
          h2sLevelMotor2: data[38],
          filterLoad: data[39],
          filterLoad2: data[40],
          h2sSensorFirmwareVersion: data[56],
          h2sSensorFirmwareVersion2: data[57],
          biotronicFirmwareVersion: data[54],
          biotronicFirmwareVersion2: data[55],
          bioguardVersion: data[77],

          siloType: data[59],
          feedingVolume: data[12],
          feedingCyclesPerDay: data[47],
          reactorLevel: data[14],
          autoFeedingStartHour: data[30],
          autoFeedingActive: data[16],
          intakeTimeout: data[28],
          feedingBalance: data[35],
          pumpOutFlowRate: data[15],
          kiloPerPulse: data[25],
          startHourLastFeeding: data[73],

          minGasBufferLevel: data[34],
          maxGasBufferLevel: data[33],
          defaultMinGasBufferLevel: data[95],
          defaultMaxGasBufferLevel: data[94],
          airInjectionTime: data[22],
          maxAirInjectionTime: data[74],
          oilInjectionsEveryXMin: data[60],
          oilInjectionsForXSec: data[61],
          torchType: data[21],
          frostIndex: data[17],

          reactorTempTarget: data[13],
          coolingWaterTemp: data[50],
          antiFreezeIndex: data[17],
          extraHeating: data[18],
          mixEveryXMinutes: data[41],
          mixForXSeconds: data[42],
          blockMixingFrom: data[43],
          blockMixingTo: data[44],
          blockMixingFrom2: data[45],
          blockMixingTo2: data[46],

          installationIdentifier: data[1],
          firmwareVersion: data[2],
          printId: data[70],
          cooperationContract: data[27],
          ip: data[8],
          mask: data[9],
          gate: data[10],
          dns: data[11],
          slowTensionError: data[19],
          overTemperatureFlueGasHeatExchanger: data[20],
          rebootMainPrint: data[53],
          mixerMaxPower: data[82],
          utcOffset: data[83],
          amountOfEngines: data[5],
          upsEnabled: data[76],
          easyStart: data[64],
          easyStart2: data[65],
          foamBeaterInstalled: data[66],
          oilInjectionsInstalled: data[69],
          coolwaterValveInstalled: data[75],
          monitoringsRelais: data[71],

          hostname: data[3],
          permanence: data[96],
          ssr: data[72],
          language: data[29],

          // enableNcrfg: data[84],
          // droopSetting: data[67],
          // frequencyThreshold: data[68],
          // offDelay: data[90],
        };
      });
    },
    setGozFasts(state, action) {
      const data = action.payload;
      return produce(state, draft => {
        draft.goz = {
          ...draft.goz,
          // alarm: data[38],
          // gatekeeperValve: data[39],
          // tempGas: data[59],
          // tempOilCooler: data[60],
          // TempReactorIn: data[2],
          // TempReactorOut: data[3],
          // ownConsumption: data[17],
          // heatPumpConsumption: data[15],
          // waterPressure: data[52],
          // reactorLevel: data[4],
          // torch: data[61],
          // foamBeater: data[18],
          // release: data[19],
          // gasValve1: data[20],
          // gasValve2: data[21],
          // airInjection: data[23],
          // gasCooler: data[25],
          // // blower: data[31], 

          // intakePump: data[29],
          // mixer: data[30],
          // exitValve: data[27],
          // ownConsumptionSetting: data[40],
          // heatPump: data[41],

          // 2025
          gasBufferLevel: data[5],
          blowerPressure: data[66],
          reactorTemp: data[1],
          h2sAfterFilter: data[21],
          ch4BiogasSupply: data[18],
          co2BiogasSupply: data[19],
          o2BiogasSupply: data[20],
          gasTemp: data[11],
          oilCoolerTemp: data[12],
          compressorSpeed: data[71],
          compressorOutPressure: data[72],
          compressorOutTemp: data[73],
          biogasTempAfterCool: data[75],
          biogasTempAfterReheat: data[43],
          biogasPressureBeforeMembrane: data[42],
          valveMembrane: data[54],
          stage3Pressure: data[64],
          pressureAfterMembrane: data[65],
          biomethaneFlowNorm: data[22],
          biomethaneDewpoint: data[63],
          wobbe: data[38],
          ch4Biomethane: data[23],
          co2Biomethane: data[24],
          o2Biomethane: data[25],
          valveGrid: data[55],
          state: data[57],
          errors: data[14],
          compressorState: data[53],
          compressorSpeed: data[71],
          p1PressureOutletBlower: data[66],
          p2PressureOutletBlowerSetpoint: data[67],
          pressureDropInletFilter: data[68],
          p3PressureOutletCompressor: data[72],
          pressureDropOilFilter: data[74],
          pressureDropFilterTrain: data[69],
          p4PressureInletMembranes: data[42],
          p5PressureMembraneStage3: data[64],
          p6PressureOutletMembranes: data[65],
          t1TemperatureAfterGascooler: data[11],
          t2TemperatureInletCompressor: data[70],
          t3TemperatureOutletCompressor: data[73],
          t4TemperatureAfterCooling: data[75],
          t5TemperatureAfterReheating: data[43],
          biomethaneFlow: data[22],
          biomethaneDewpoint: data[63],
          wobbe: data[38],
          ch4Biomethane: data[23],
          co2Biomethane: data[24],
          o2Biomethane: data[25],
          h2sBiomethane: data[26],
          wobbeHourAverage: data[39],
          valveMembrane: data[54],
          valveGrid: data[55],
          injectedGas: data[41],
          consumedGas: data[62],

          hydrostaticPressure: data[4],
          waterTempIn: data[2],
          waterTempOut: data[3],
          waterPressure: data[52],
          mixer: data[30],
          statusValvePumpOut: data[27],
          valveCurrentActuator: data[7],
          feedingStatus: data[32],
          feedingError: data[46],
          intakePumpTime: data[36],
          intakePumpAmount: data[37],
          exitPumpTime: data[34],
          exitPumpAmount: data[35],
          powerConsumption: data[13],
        };
      });
    },
    setGozSlows(state, action) {
      const data = action.payload;
      return produce(state, draft => {
        draft.goz = {
          ...draft.goz,
          // carbonFilter: data[64],
          // airInjectionTime: data[22],
          // maxAirInjectionTime: data[74],
          // h2sReactor: data[4],
          // h2sCarbonFilter1: data[37],
          // h2sCarbonFilter2: data[38],
          // // h2sSkid: data[51],
          // // h2sReactorHost: data[12],
          // loadCarbonFilter1: data[39],
          // loadCarbonFilter2: data[40],

          // 2025
          type: data[97],
          minGasBufferLevel: data[34],
          maxGasBufferLevel: data[33],
          h2sLevelReactor: data[4],
          filterSelect: data[7],
          h2SBetweenFilter1: data[37],
          h2SBetweenFilter2: data[38],

          reactorTempTarget: data[13],
          reactorLevel: data[14],
          siloType: data[59],
          frostIndex: data[17],
          mixerMaxPower: data[82],
          autoFeedingActive: data[16],
          feedingVolume: data[12],
          feedingCyclesPerDay: data[47],
          autoFeedingStartHour: data[30],
          feedingBalance: data[35],
          intakeTimeout: data[28],
          pumpOutFlowRate: data[15],
          kiloPerPulse: data[25],
          intakeTimeout: data[28],
          startHourLastFeeding: data[73],
          airInjectionTime: data[22],
          maxAirInjectionTime: data[74],
          torchType: data[21],
          oilInjectionsForXSec: data[61],
          oilInjectionsEveryXMin: data[60],
          h2sLevelReactor: data[4],
          filterLoad: data[39],
          filterLoad2: data[40],

          hostname: data[3],
          installationIdentifier: data[1],
          rebootMainPrint: data[53],
          utcOffset: data[83],
          ip: data[8],
          mask: data[9],
          gate: data[10],
          dns: data[11],

          firmwareVersion: data[2],
          printId: data[70],
          h2sSensorFirmwareVersion: data[56],
          idH2s: data[88],

          gasBufferLevelSetting: data[32],
        };
      });
    },
    setClearData: (state) => {
      // Reset the state to its initial value
      return initialState;
    },
  },
});

export const { setWkkFasts, setGozFasts, setWkkSlows, setGozSlows, setClearData } = machineSlice.actions;

export default machineSlice.reducer;
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new e.Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="68b1bae7-102b-5c14-ba4b-4bf16c7c5b76")}catch(e){}}();
//# debugId=68b1bae7-102b-5c14-ba4b-4bf16c7c5b76
